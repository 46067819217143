<template>
  <div class="px-7 py-7">
    <v-data-table
      fixed-header
      hide-default-footer
      :headers="headers"
      :items="items"
      :loading="loading"
      class="elevation-8"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
      :items-per-page="page_info.detail_count"
      :server-items-length="page_info.detail_count"
      :options.sync="dataOptions"
    >
      <!-- items template -->
      <template v-slot:item="props">
        <tr>
          <td class="text-left">
            <div>
              <span
                v-if="props.item.player_list != null"
                style="font-size: 20px"
              >
                {{ props.item.assemble_city }},{{
                  props.item.assemble_state
                }}-FA({{ props.item.sequence }})
                <span
                  v-for="(player, index) in props.item.player_list"
                  :key="index"
                >
                  {{ player.name }},
                </span>
              </span>
              <span v-else style="font-size: 20px">
                {{ props.item.assemble_city }},{{
                  props.item.assemble_state
                }}-FA({{ props.item.sequence }}){{ props.item.team_name }}
              </span>
              <v-chip
                class="actionColumn-btn"
                color="#F2F2F2"
                @click="
                  toggleAssemblyModal({
                    show: true,
                    type: 'edit',
                    id: props.item.id,
                  })
                "
                v-if="
                  props.item.user_type == 'admin' ||
                  props.item.user_type == 'Sales Representative' ||
                  props.item.user_type == 'Event Support Staff'
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                      size="22"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </v-chip>
            </div>
            <div
              v-if="
                props.item.user_type == 'admin' ||
                props.item.sub_user_type == 'Support Manager'
              "
            >
              <v-switch
                color="#067605"
                v-model="props.item.approved"
                @change="changeStatus(props.item.approved, props.item.id)"
              >
                <template v-slot:label>
                  <div
                    :style="
                      props.item.approved ? 'color:#067605' : 'color:#8C8C8C'
                    "
                  >
                    <div v-if="props.item.approved">Approved</div>
                    <div v-else>Not Approved</div>
                  </div>
                </template>
              </v-switch>
            </div>
            <br />
            <b>Date:</b>{{ props.item.assemble_date_2 }}
            <br />
            <b>Time:</b>{{ props.item.start_time_str }}-{{
              props.item.end_time_str
            }}
            <br />
            <b>No. Of Assemblies : </b>{{ props.item.total_assemblies }}
            <br />
            <b>No. Of Student : </b>{{ props.item.total_student }}
            <br />
            <br />
            <b>Meeting Time : </b>{{ props.item.meeting_time_str }}
            <br />
            <b>Meeting Place : </b>{{ props.item.school }}
            <br />
            <b>Meeting Address : </b>{{ props.item.address }}
            <br />
            <b>Driver Name : </b>{{ props.item.driver_name }}
            <br />
            <b>Driver Cell : </b>{{ props.item.phone }}
            <br />
            <br />
            <b> Description : </b>
            <br />
            <br />
            <div v-html="props.item.note" style="line-height: 1.2"></div>
            
            <div
              v-for="(group, index) in props.item.assemble_school_groups"
              :key="index"
            >
            <strong>School {{index+1}}</strong>  <br /> 
              <b>School Name:</b> {{ group.school_name }}  <br />
              <b>School Address:</b> {{ group.school_address }}  <br />
              <b>Start Time:</b> {{ group.start_time_str }}  <br />
              <b>End Time: </b>{{ group.end_time_str }}  <br />
              <b>Location: </b>{{ group.location_in_school }}  <br />
              <b>Number of Students:</b> {{ group.number_of_students }}  <br />
              <b>Principal Name: </b>{{ group.principal_name }}  <br />
             <b>Principal Email: </b>{{ group.principal_email }}  <br />
              <b>Principal Phone: </b>{{ group.principal_phone }}  <br />
              <b>Grade: </b>{{ group.grades }}  <br />
              <b>Visit: </b>{{ group.visit }}<br />
               <div v-html="'<strong>Note:</strong> ' + group.note"></div><br /><br />
              <!-- Add more fields as needed    -->
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <delete-modal @reload="deleteData()"></delete-modal>
    <assembly-schedual-modal
      @reload="getAssemblyData()"
    ></assembly-schedual-modal>
  </div>
</template>
<script>
import moment from "moment";
import { mapActions } from "vuex";
import {
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  SORTING_KEY,
} from "@/constants/APIKeys";
import Axios from "@/api/BaseAxios";
import {
  ASSEMBLY_SCHEDUAL_API_LIST,
  ASSEMBLY_SCHEDUAL_API_DELETE,
  API_ADD_ASSEMBLY_CALENDAR,
} from "@/constants/APIUrls";
import _ from "lodash";
export default {
  name: "GameTable",
  components: {
    AssemblySchedualModal: () =>
      import("./AssemblySchedualModal/AssemblySchedualModal"),
    DeleteModal: () => import("../DeleteModal/DeleteModal"),
  },
  data() {
    return {
      gameCount: "",
      yearList: [],
      assembleSchoolGroups: [],
      yearFilter: "",
      showFlag: false,
      currentState: false,
      adminAccess: localStorage.getItem("adminAccess"),
      subAdminAccess: localStorage.getItem("subadminAccess"),
      dataOptions: { sortBy: [], sortDesc: [] },
      items: [],
      dates: [],
      menu: false,
      start_date: "",
      end_date: "",
      loading: false,
      [PAGE_INFO]: {
        [PAGE_NUMBER]: 1,
        [TOTAL_PAGE]: 5,
        [ITEMS_PER_PAGE]: 50,
      },
      itemsPerPageList: [10, 15, 20, 30, 40, 50, 100],
      [SEARCH]: "",
      [SORTING_KEY]: "",
      campaignlistdata: [],
      headers: [
        {
          sortable: false,
          text: "School Info",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
    };
  },
  computed: {
    dateRangeText: {
      get() {
        if (this.dates) {
          if (this.dates.length > 1) {
            let date = [
              moment(this.dates[0]).format("MM-DD-YYYY"),
              moment(this.dates[1]).format("MM-DD-YYYY"),
            ];
            return date.join(" - ");
          } else {
            return "";
          }
        } else {
          return "";
        }
        // return this.dates.join("  -  ");
      },
      set(newDates) {
        this.dates = newDates || [];
        return this.dates.length > 1 ? this.dates.join("  -  ") : "";
      },
    },
  },
  watch: {
    dates: function () {
      if (this.dates.length !== 1) {
        this.debounceGetGameManagementData();
      }
    },
    "dataOptions.sortBy": function () {
      if (this.dataOptions.sortBy.length !== 0) {
        this.sorting_key = this.dataOptions.sortDesc[0]
          ? `-${this.dataOptions.sortBy[0]}`
          : this.dataOptions.sortBy[0];
        this.getAssemblyData();
      } else {
        this.sorting_key = "";
        this.getAssemblyData();
      }
    },
    search() {
      this.debounceGetGameManagementData();
    },
    "page_info.page_number": function () {
      this.getAssemblyData();
    },
    "page_info.detail_count": function () {
      this.getAssemblyData();
    },
  },
  methods: {
    ...mapActions({
      toggleDeleteModal: "deleteManagement/toggleModal",
      toggleAssemblyModal: "gameManagement/toggleAssembly",
      showToast: "snackBar/showToast",
    }),
    deleteAssemblyData() {
      const successHandler = (res) => {
        console.log(res);
        this.showToast({
          message: "Deleted successfully.",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        this.showToast({
          message: res,
          color: "e",
        });
      };
      const finallyHandler = () => {
        this.getAssemblyData();
      };
      let formData = {};
      formData["id"] = this.$store.state.deleteManagement.deleteModal.deleteID;
      Axios.request_DELETE(
        ASSEMBLY_SCHEDUAL_API_DELETE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getAssemblyData() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.assemble_list;
        this.items.forEach((item) => {
          if (item.assemble_school_groups) {
            // You can store them separately if needed, or log them for now
            console.log(item.assemble_school_groups);

            // If you want to store them in a separate variable in your component:
            // this.assembleSchoolGroups = item.assemble_school_groups;
            console.log("sadd", this.assembleSchoolGroups);
          }
        });
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        ASSEMBLY_SCHEDUAL_API_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    changeStatus(status, id) {
      const successHandler = (res) => {
        console.log(res.data, "status changed");
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["id"] = id;
      formData["status"] = status;
      Axios.request_PATCH(
        API_ADD_ASSEMBLY_CALENDAR,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    this.debounceGetGameManagementData = _.debounce(this.getAssemblyData, 500);
  },
  created() {},
};
</script>
<style scoped>
.v-data-table.elevation-8.v-data-table--fixed-header.v-data-table--has-top.theme--light {
  border-radius: 6px;
}
.alignSelf-center {
  align-self: center;
}
* {
  font-family: Lato;
}
.v-btn >>> span {
  letter-spacing: 0px;
  font-weight: 600;
}
.user-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 315%;
  color: white;
  letter-spacing: 0px;
}
.textHeading {
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
}
.textHeadinglabels {
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.ONGOING {
  background-color: rgba(231, 111, 0, 0.12);
  color: #e76f00;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.UPCOMING {
  background-color: rgba(0, 133, 255, 0.12);
  color: #0085ff;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.COMPLETED {
  background-color: rgba(6, 118, 5, 0.12);
  color: #067605;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
span.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  height: 45px;
  width: 38px;
  cursor: pointer;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
